<template>
  <el-dialog title="查看" :visible.sync="roleDetailVisible" :close-on-click-modal="false" width="600px" append-to-body :before-close="handleClose">
    <el-form :model="ruleForm" ref="ruleForm" label-width="130px" class="demo-ruleForm">
      <el-form-item label="角色名称:" prop="roleName">
        <span>{{ruleForm.roleName}}</span>
      </el-form-item>
      <el-form-item label="角色描述:"  prop="describes">
        <span>{{ruleForm.describes}}</span>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button type="primary" @click="handleClose">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    name: "addOrEditDilog",
    props: {
      roleDetailVisible: {
        type: Boolean,
        default: false
      },
      detailData: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data() {
      return {
        ruleForm: {
          describes: '',
          roleName: ''
        }
      }
    },
    mounted() {
      this.ruleForm = this.detailData
    },
    methods: {
      handleClose() {
        this.$emit('update:roleDetailVisible', false)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .w230 {
    width: 300px;
  }
  .info {
    margin-left: 130px;
    width: 300px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #666666;
  }
</style>
